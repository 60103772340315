// src/features/configSlice.js
import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
    name: 'config',
    initialState: {
        data: null, // Holds the configuration data
        loading: true, // Loading state
        error: null, // Error state
    },
    reducers: {
        setConfig: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const { setConfig, setError } = configSlice.actions;

export default configSlice.reducer;
