import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, total,subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" height="100%" padding="15px" borderRadius={5} backgroundColor={colors.cardsBackGround}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          {icon}
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.cardsAccent }}
          >
            {title}
          </Typography>
          {total!==0 && (
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: colors.cardsProgressTrack }}
          >
            { `out of ${total}`}
          </Typography>
          )}
        </Box>
        {total!==0 && (
          <Box>
            <ProgressCircle progress={progress} />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.cardsAccent }}>
          {subtitle}
        </Typography>
        {increase!==0 && (
        <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.cardsAccent }}
        >
          {increase}%
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default StatBox;
