import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useState } from "react";

const Spare = () => {
  const [spareName, setSpareName] = useState("");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  var action = "create";

  const handleFormSubmit = (values) => {
    var joke = {};
    var fields = [];
    fields = Object.keys(values);
    if(action==="create"){
    console.log(values);
    joke = {
      "data": {
          "action":action,
          "name": spareName,
          "fields": fields
      }
  };
    }else{
      console.log(values);
      var fields_values = []
      fields.map((field)=>{
        fields_values.push({
          "name":field,
          "value":values[field]
        })
      })
      joke = {
        "data": {
            "action":action,
            "name": spareName,
            "fields": fields_values
        }
    };
    console.log(joke);

    }

    //const raw = "{\r\n    \"data\": {\r\n        \"action\":\"create\",\r\n        \"name\": \"test\",\r\n        \"fields\": [\r\n            \"name\",\r\n            \"status\"\r\n        ]\r\n    }\r\n}";


    const requestOptions = {
      mode: "no-cors",
      method: "POST",
      body: JSON.stringify(joke),
      redirect: "follow"
    };
    
    fetch("http://kjmmcharityofhumanity.org/api/spares.php", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));

      action = "insert";
    


  //   if(action==="create"){
  //   console.log(values);
  //   action = "insert";
  //   }else{
  //     console.log(values);
  //   }

  //   const joke = {
  //     "data": {
  //         "action":"create",
  //         "name": "test_three",
  //         "fields": [
  //             "name",
  //             "status"
  //         ]
  //     }
  // };
  //   const options = {
  //     method: 'POST',
  //     body: JSON.stringify(joke)
  //   };
  //   fetch('http://kjmmcharityofhumanity.org/api/spares.php', options)
  //     .then(response => response.json())
  //     .then(data => console.log(data))
  //     .catch(error => console.error(error));

  };

  return (
    <Box m="20px">
      <Header title="CREATE USER" subtitle="Create a New User Profile" />
      <Box mb="20px">
      <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Soare Name"
                onChange={(e)=>setSpareName(e.target.value)}
                value={spareName}
                name="name"
                sx={{ gridColumn: "span 2" }}
              />
         </Box> 

      <Formik

        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >



              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact}
                name="contact"
                error={!!touched.contact && !!errors.contact}
                helperText={touched.contact && errors.contact}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 1"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address 2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address2}
                name="address2"
                error={!!touched.address2 && !!errors.address2}
                helperText={touched.address2 && errors.address2}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Spare
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address1: "",
  address2: "",
};

export default Spare;
