import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme, IconButton,MenuItem } from '@mui/material';
import { Add, Sync } from "@mui/icons-material";
import apiClient from '../../networking/axiosconfig';
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { capitalizeFirstLetter, getUserNameFromId, isJsonObject } from '../../Utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate,useLocation } from "react-router-dom";
import ImageCell from '../../components/ImageCell';

const CrudPage = () => {
    const { data: config, loading, error } = useSelector((state) => state.config);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { model } = useParams();
    const { search } = useLocation();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState(null);
    const [time, setTime] = useState("");

    const [filters, setFilters] = useState({}); // State to store search filters
    const [filteredRows, setFilteredRows] = useState([]); // State for filtered rows
    const hiddenColumns = [
        model==="content-uploads"?'':'createdAt', 
        'updatedAt',
        'deletedAt', 
        'lastName', 
        'Blocks', 
        'Coordinator', 
        'District', 
        'Block', 
        'ParavetDetails', 
        'DocDetails', 
        'DriverDetails', 
        'Villages',
        'BusinessType',
        'route',
        'showSubmitButton'
    ];
    const columnNameOverrides = {
        firstname: 'Name',   // Map original column names to new header names
        role: 'Role ID',
        isActive: 'Active',
        userId: 'User Name',
        createdAt: 'Time'
    };
    const navigate = useNavigate();
    const masterData = JSON.parse(localStorage.getItem("crudMasterData"));
    useEffect(() => {
        console.log(masterData)
        setFilters({})
        fetchData();
    }, [model]);

    const getApiPath = (model, action) => {
        if (model === "users" && action === "post") {
            return `${model}/register`;
        } else if (model === "users" && action === "put") {
            return `${model}`;
        } else if (model === "users" && action === "get") {
            return `${model}`;
        }
        return `api/${model}`;
    }

    const modelMapping = {
        'users':{},    
        'business-types': { childModel: "content-blocks", foreignKey: "businessTypeId", queryParam: "businessTypeId",childKey:"ContentBlocks" },
        'content-blocks': { childModel: "content-forms", foreignKey: "contentBlockId", queryParam: "contentBlockId",childKey:"ContentForms" },
        'content-forms': { childModel: "content-sections", foreignKey: "formId", queryParam: "formId",childKey:"ContentSections" },
        'content-sections': { childModel: "content-types", foreignKey: "sectionId", queryParam: "sectionId",childKey:"ContentTypes" },
        'content-types': { childModel: "content-uploads", foreignKey: "contentTypeId", queryParam: "contentTypeId",childKey:"ContentUploads" },
        'content-uploads': { childModel: "content-uploads", foreignKey: "contentTypeId", queryParam: "contentTypeId",childKey:"" }
    };
      const getQueryParams = () => {
        const queryParams = new URLSearchParams(search);
        const paramKey = [...queryParams.keys()][0]; // Get first query parameter key
        const paramValue = queryParams.get(paramKey); // Get its value
      
        return { key: paramKey, value: paramValue };
      };

      const getParentId = () => {
        const queryParams = new URLSearchParams(search);
        const paramKey = [...queryParams.keys()][0]; // Get first query parameter key
        const paramValue = queryParams.get(paramKey); // Get its value
      
        return { [paramKey]: paramValue };
      };


      const handleNavigate = (currentModel, row) => {
        const nextModel = modelMapping[currentModel]?.childModel;
        const queryParam = modelMapping[currentModel]?.queryParam;
        const prevModelMaster = filteredRows.map((item) => ({ id: item.id, name: item.name||item.label,format:'image'}));

        localStorage.setItem("crudMasterData", JSON.stringify(prevModelMaster)); // Store in localStorage
   
        if (nextModel && queryParam) {
          navigate(`/crud/${nextModel}?${queryParam}=${row.id}`);
        }
      };

    const fetchData = async () => {
        try {
            setData([]); // Clear data to prevent old data from showing
            var response_columns;
            const response = await apiClient.get(`${config.API_BASE_URL}/${getApiPath(model, "get")}`);
            if(model!=="users"){
                response_columns = await apiClient.get(`${config.API_BASE_URL}/api/describe/${model}`);
            }
            const queryParams = getQueryParams();
            const fetchedData = response.data.filter(item => item[queryParams.key] == queryParams.value); //.sort((a, b) => b.id - a.id);
            setData(fetchedData);
            setFilteredRows(fetchedData);
            if(model==="users"){
                localStorage.setItem("userMasterData", JSON.stringify(fetchedData)); // Store in localStorage
                response_columns = { data:Object.keys(fetchedData[0])};
            }
            setTime(`As of ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`);
                const tempColumns = response_columns.data;
                tempColumns.push(modelMapping[model].childKey)
                console.log("desc===>"+tempColumns);
                const updatedColumns = tempColumns
                    .filter((key) => !hiddenColumns.includes(key)  && key!=getQueryParams().key)
                    .map((key) => ({
                        field: key,
                        headerName: columnNameOverrides[key] || capitalizeFirstLetter(key),
                        flex: 1,
                        editable: false,
                        renderCell: (params) =>
                            Array.isArray(params.value) ? (
                              <>
                                <Button variant="contained" size="small" onClick={() => handleNavigate(model,params.row)}>
                                  Manage  {params.value.length} Item(s)
                                </Button>
                              </>
                            ) : (
                                 (typeof params.value === "string" && params.value.startsWith("uploads/"))?
                                <ImageCell imageUrl={config.API_BASE_URL+"/"+params.value}></ImageCell>:
                                 key==="userId"?
                                 getUserNameFromId(params.value):
                                params.value
                            ),
                    }));
    
                setColumns(updatedColumns);
    
                // Ensure filters are updated after setting columns
                setTimeout(() => {
                    const initialFilters = {};
                    updatedColumns.forEach((col) => (initialFilters[col.field] = ''));
                    setFilters(initialFilters);
                }, 0);
        } catch (error) {
            console.error(error);
        }
    };
    

    const handleSave = async () => {
        try {
            if (isEdit) {
                if (model === 'users') {
                    formData.role = formData.role.toString();
                }
                await apiClient.put(`${config.API_BASE_URL}/${getApiPath(model, "put")}/${editId}`, {...formData});
            } else {
                
                if (model === 'users') {
                    formData.lastName = ' '
                    const role = formData.role.toString();
                // Define the default password based on the role
                let defaultPassword = '';
                defaultPassword = ["1", "2", "3"].includes(role) ? 'Test@1234' : 'mvu@web';
                formData.password = defaultPassword; // Set the password in the formData
                }
                await apiClient.post(`${config.API_BASE_URL}/${getApiPath(model, "post")}`, {...formData,...getParentId(),showSubmitButton:model==="content-forms","route":"dynamicForm"});
            }
            fetchData();
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await apiClient.delete(`${config.API_BASE_URL}/api/${model}/${id}`);
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({});
        setIsEdit(false);
        setEditId(null);
    };
    const handleFilterChange = (field, value) => {
        setFilters((prev) => ({
            ...prev,
            [field]: value,
        }));
    };
    useEffect(() => {
        if(filters!={}){
        filterData();
        }
    }, [filters]);
    const filterData = () =>  {
        console.log("five","test")
        const newFilteredRows = data.filter((row) =>
            Object.keys(filters).every((field) =>
                row[field]?.toString().toLowerCase().includes(filters[field].toLowerCase())
            )
        );
        setFilteredRows(newFilteredRows);
    }
    const openForm = (row = null) => {
        if (row) {
            setIsEdit(true);
            setEditId(row.id);
            setFormData(row);
        }
        setOpen(true);
    };

    const addActionsColumn = () => {
        if (model!="content-uploads" && ( localStorage.getItem("role") == 5 || localStorage.getItem("role") == 6))
            return {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                renderCell: (params) => (
                    <>
                        <IconButton
                            color="secondary"
                            onClick={() => openForm(params.row)}
                            style={{ marginRight: 10 }}
                        >
                            <EditIcon />
                        </IconButton>
                        {localStorage.getItem("role") == 6 &&
                            <IconButton
                                color="error"
                                onClick={() => handleDelete(params.row.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                    </>
                ),
            }
        else {
            return {

            }
        }
    }

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={`${capitalizeFirstLetter(model)} List`} subtitle={time} />
                <Box display="flex" gap={2}>
                    {( model!="content-uploads" && (localStorage.getItem("role") == 5 || localStorage.getItem("role") == 6)) &&
                        <Button
                            onClick={() => {
                                openForm();
                            }}
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            <Add sx={{ mr: "10px" }} />
                            Add
                        </Button>
                    }
                    <Button
                        onClick={() => {
                            fetchData();
                        }}
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",

                        }}
                    >
                        <Sync sx={{ mr: "10px" }} />
                        Refresh
                    </Button>
                </Box>
            </Box>
            {/* <Box display="flex" gap={2} mb={2}>
                {columns.map((col) => (
                    <TextField
                        key={col.field}
                        label={`Search ${col.headerName}`}
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleFilterChange(col.field, e.target.value)}
                        value={filters[col.field] || ''}
                    />
                ))}
            </Box> */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            ><Box
                gridColumn="span 12"
                gridRow="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >

                    <DataGrid
                        rows={filteredRows}
                        columns={[
                            ...columns,
                            addActionsColumn(),
                        ]}
                        getRowId={(row) => row.id}
                    />
                </Box>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEdit ? 'Edit Record' : 'Add Record'}</DialogTitle>
                <DialogContent>
                    {columns
                        .filter((col) => col.field !== "id" && col.field !== "actions" && col.headerName != capitalizeFirstLetter(modelMapping[model].childKey))
                        .map((col) => {
                            const isActiveField = col.headerName === "Active";
                            return isActiveField ? (
                                <TextField
                                    key={col.field}
                                    select
                                    label={col.headerName}
                                    value={formData[col.field]?"Yes": "No"}
                                    onChange={(e) =>
                                        setFormData({ ...formData, [col.field]: e.target.value=="Yes"?true:false })
                                    }
                                    fullWidth
                                    margin="dense"
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </TextField>
                            ) : (
                                <TextField
                                    key={col.field}
                                    label={col.headerName}
                                    value={formData[col.field] || ""}
                                    onChange={(e) =>
                                        setFormData({ ...formData, [col.field]: e.target.value })
                                    }
                                    fullWidth
                                    margin="dense"
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CrudPage;
