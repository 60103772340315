import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const StatBoxMultipleLines = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box 
     justifyContent="space-between"
     display="flex"
     flexDirection={"column"}
      width="100%" 
      height="100%" 
      padding="10px 15px" 
      borderRadius={5} 
      backgroundColor={colors.cardsBackGround}>
       {data.length > 0 && data.map((transaction, i) => (
            <Box
              key={`${transaction.title}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="1px"
            >
              <Box>
                <Typography
                  color={colors.cardsAccent}
                  variant="h5"
                 
                >
                  {transaction.title}
                </Typography>
              </Box>
              <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.cardsAccent }}
          >
                {transaction.count}</Typography>
            </Box>
            
          ))} 
    </Box>
  );
};

export default StatBoxMultipleLines;
