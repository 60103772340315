import { useState ,useEffect  } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Report from "./scenes/report";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Spare from "./scenes/spare";
import LoginPage from "./LoginPage"
import CrudPage from './scenes/crud';
import { useNavigate } from 'react-router-dom';
import MVU from './scenes/mvu'
import ExcelToDataGrid from "./scenes/mvu/ExcelToDataGrid";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated,setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("isAuthenticated");
    if (storedUsername=='yes') {
      setIsAuthenticated(true);
    }
  },[isAuthenticated]);
  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        { 
        !isAuthenticated &&
        <Routes>
        <Route path="/*" element={<LoginPage callBack={()=>{
          setIsAuthenticated(true)
          sessionStorage.setItem("isAuthenticated", 'yes');
        }
          }/>} />
        </Routes>
      }
      {
         isAuthenticated && <>
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            {/* <Topbar setIsSidebar={setIsSidebar} /> */}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/logout" element={<Dashboard callBack={()=>{
                  setIsAuthenticated(false)
                  sessionStorage.removeItem("isAuthenticated");
                  navigate('/');
            }
          }/>} />
              <Route path="/team" element={<Team />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/report/:model" element={<Report />} />
              <Route path="/mvu" element={<MVU />} />
              <Route path="/excel" element={<ExcelToDataGrid />} />
              <Route path="/spares" element={<Spare />} />
              <Route path="/form" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/crud/:model/" element={<CrudPage />} />
              <Route path="/crud/:model/:submodel" element={<CrudPage />} />

            </Routes>
          </main>
          </>
    }
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
