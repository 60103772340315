import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import apiClient from "../../networking/axiosconfig";
import { useSelector } from "react-redux";

const ExcelToDataGrid = () => {
    const { data: config, loading, error } = useSelector((state) => state.config);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [users,setUsers] = useState([]);
  const fetchUsers = async (event) => {
    const users = await apiClient.get(`${config.API_BASE_URL}/api/users`)
    {   setUsers(users.data)
        handleFileUpload(event,users.data)
    }
}
  const handleFileUpload = (event,users) => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0]; // Get the first sheet
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet data to JSON
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Create columns based on the first row (header row)
      const headers = jsonData[0];
      const dataColumns = headers.map((header, index) => ({
        field: header || `column_${index}`,
        headerName: header || `Column ${index + 1}`,
        flex: 1,
      }));

      // Create rows based on the remaining rows
      const dataRows = jsonData.slice(1).map((row, index) => {
        const rowData = {};
        headers.forEach((header, colIndex) => { 
          rowData[header || `column_${colIndex}`] = row[colIndex] || "";
        });
        
            const filteredUser = users.filter(item=>item.mobile===(rowData["Doctor Mobile"].toString()));
            console.log("filtered",filteredUser,rowData["Doctor Mobile"]);
            rowData["Driver Role"] = filteredUser[0]? filteredUser[0].role:"N/A"
            return { id: index, ...rowData };
      });

      setColumns(dataColumns);
      setRows(dataRows.filter(item=>item["Driver Role"]!==3 && item["Doctor Mobile"]));
    };

    reader.readAsBinaryString(file);
  };
  return (
    <div style={{ height: 600, width: "100%" }}>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={fetchUsers}
        style={{ marginBottom: "1rem" }}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5, 10, 100]}
        disableSelectionOnClick
      />
    </div>
  );
};

export default ExcelToDataGrid;
