import React from 'react';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const PolygonMap = () => {
  // Coordinates for the polygon (swapped to [latitude, longitude])
  const polygonCoordinates = [
    [27.44133, 83.90762],
    [27.44132, 83.90865],
    [27.44239, 83.91351],
    [27.44373, 83.91722],
    [27.44524, 83.92181],
    [27.44753, 83.92602],
    [27.451, 83.93218],
    [27.4521, 83.93482],
    [27.45227, 83.93689],
    [27.45209, 83.93757],
    [27.45184, 83.93778],
    [27.44924, 83.93515],
    [27.44542, 83.93381],
    [27.44185, 83.93275],
    [27.43702, 83.9319],
    [27.43489, 83.9319],
    [27.43113, 83.93289],
    [27.4285, 83.93444],
    [27.42687, 83.9362],
    [27.42506, 83.93875],
    [27.4245, 83.94017],
    [27.422, 83.9391],
    [27.42021, 83.93804],
    [27.41707, 83.93628],
    [27.41518, 83.93581],
    [27.4134, 83.93557],
    [27.40845, 83.93757],
    [27.4051, 83.93874],
    [27.3997, 83.93909],
    [27.39666, 83.93932],
    [27.39436, 83.93979],
    [27.39248, 83.94049],
    [27.39218, 83.94064],
    [27.39217, 83.94048],
    [27.39168, 83.93821],
    [27.39127, 83.93684],
    [27.39077, 83.93439],
    [27.39052, 83.9324],
    [27.39044, 83.9313],
    [27.39035, 83.93003],
    [27.39077, 83.92903],
    [27.39092, 83.92867],
    [27.39197, 83.92776],
    [27.39383, 83.92631],
    [27.39552, 83.9254],
    [27.3956, 83.92494],
    [27.39576, 83.92394],
    [27.39544, 83.92331],
    [27.39543, 83.92231],
    [27.39583, 83.92094],
    [27.39617, 83.92019],
    [27.39644, 83.92031],
    [27.39799, 83.92113],
    [27.39944, 83.92195],
    [27.40018, 83.92249],
    [27.40083, 83.92313],
    [27.40224, 83.92441],
    [27.40305, 83.9245],
    [27.40419, 83.9255],
    [27.40476, 83.92559],
    [27.40508, 83.92496],
    [27.40564, 83.9235],
    [27.40596, 83.92205],
    [27.4062, 83.9205],
    [27.40668, 83.9195],
    [27.40684, 83.91841],
    [27.40724, 83.91741],
    [27.40738, 83.91705],
    [27.4083, 83.91705],
    [27.40951, 83.91669],
    [27.41072, 83.91605],
    [27.41153, 83.91478],
    [27.41177, 83.91333],
    [27.41217, 83.91169],
    [27.41289, 83.91069],
    [27.41346, 83.91051],
    [27.41378, 83.91015],
    [27.41394, 83.90952],
    [27.41402, 83.90869],
    [27.4141, 83.90752],
    [27.41409, 83.9056],
    [27.41425, 83.9046],
    [27.41425, 83.90457],
    [27.41558, 83.90457],
    [27.41663, 83.90457],
    [27.41744, 83.90466],
    [27.41833, 83.90503],
    [27.41915, 83.90585],
    [27.41996, 83.90612],
    [27.42166, 83.90613],
    [27.42271, 83.90531],
    [27.42376, 83.90513],
    [27.42457, 83.90467],
    [27.42522, 83.90467],
    [27.42753, 83.90477],
    [27.43093, 83.90468],
    [27.43198, 83.90504],
    [27.43328, 83.90596],
    [27.43499, 83.90668],
    [27.43588, 83.90696],
    [27.43693, 83.90733],
    [27.43801, 83.90746],
    [27.43917, 83.90739],
    [27.44133, 83.90762],
  ];

  return (
    <MapContainer center={[27.44133, 83.90762]} zoom={13} style={{ height: "100vh", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      
      <Polygon positions={polygonCoordinates} color="blue" />
    </MapContainer>
  );
};

export default PolygonMap;
