import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ImageCell = ({ imageUrl,title }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDownload = () => {
    // Trigger the download of the image
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg"; // Customize the filename as needed
    link.click();
  };

  return (
    <div>
      {/* Small Image in the Cell */}
      <img
        src={imageUrl}
        alt="Avatar"
        style={{
          width: "80px",
          height: "80px",
          borderRadius: "5%",
          objectFit: "contain",
          cursor: "pointer",
        }}
        onClick={handleOpen} // Open the modal on click
      />

      {/* Popup Modal for Full Image */}
      <Modal open={open} onClose={handleClose} disableAutoFocus>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500, // Adjust width of the popup
            bgcolor: "white", // White background color
            boxShadow: 24, // Shadow effect
            borderRadius: "10px", // Rounded corners
            overflow: "hidden", // Ensure no overflow
            outline: "none",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 15px",
              borderBottom: "1px solid #ccc",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <Box>
              {/* Download Button */}
              <Button
                variant="contained"
                size="small"
                sx={{ marginRight: "10px" }}
                onClick={handleDownload}
              >
                Download
              </Button>
              {/* Close Button */}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Image Content */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              backgroundColor: "#fff",
            }}
          >
            <img
              src={imageUrl}
              alt="Full Avatar"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "500px", // Adjust the max height of the image
                objectFit: "contain",
              }}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ImageCell;
