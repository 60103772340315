import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme, IconButton } from '@mui/material';
import { Download, Sync } from "@mui/icons-material";
import apiClient from '../../networking/axiosconfig';
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { capitalizeFirstLetter, capitalizeWords } from '../../Utils';
import { useLocation } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ImageCell from "../../components/ImageCell"
import dayjs from "dayjs";
import VideoCell from '../../components/VideoCell';
import * as XLSX from "xlsx";

const Report = () => {
  const { data: config, loading, error } = useSelector((state) => state.config);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { model } = useParams();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [time, setTime] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  useEffect(() => {
    fetchColumnNames();
  }, [type]);

  const fetchColumnNames = async () => {
    var filters = [["id", "IN", JSON.stringify(getContentTypes(type))]];
    const query_params = { filters: JSON.stringify(filters) }
    const response = await apiClient.get(`${config.API_BASE_URL}/api/masters/content-types`, { params: query_params });
    const fetchedData = response.data;
    console.log(fetchedData);
    if (fetchedData.length > 0) {
      const serialNo = {
        id: 1000,
        name: "S.No",
        format: "readOnly",
        imageHeight: 0,
        sectionId: 0,
      };
      const districtColumn = {
        id: -3,
        name: "District",
        format: "readOnly",
        imageHeight: 0,
        sectionId: 0,
      };
      const blockColumn = {
        id: -2,
        name: "Block",
        format: "readOnly",
        imageHeight: 0,
        sectionId: 0,
      };
      const mvuColumn = {
        id: -1,
        name: "MVU",
        format: "readOnly",
        imageHeight: 0,
        sectionId: 0,
      };
      const newColumn = {
        id: 0,
        name: "Paravet Name",
        format: "readOnly",
        imageHeight: 0,
        sectionId: 0,
      };
      const timeStampColumn = {
        id: 999,
        name: "Time",
        format: "readOnly",
        imageHeight: 0,
        sectionId: 0,
      };
      const extraColumns = [
        {
          id: 998,
          name: "Cows",
          format: "readOnly",
          imageHeight: 0,
          sectionId: 0,
        },
        {
          id: 997,
          name: "Buffaloes",
          format: "readOnly",
          imageHeight: 0,
          sectionId: 0,
        },
        {
          id: 996,
          name: "Goats",
          format: "readOnly",
          imageHeight: 0,
          sectionId: 0,
        },
        {
          id: 995,
          name: "Sheep",
          format: "readOnly",
          imageHeight: 0,
          sectionId: 0,
        },
        {
          id: 994,
          name: "Pigs",
          format: "readOnly",
          imageHeight: 0,
          sectionId: 0,
        },
        {
          id: 993,
          name: "Poultry",
          format: "readOnly",
          imageHeight: 0,
          sectionId: 0,
        },
        {
          id: 992,
          name: "Others",
          format: "readOnly",
          imageHeight: 0,
          sectionId: 0,
        },
      ]

      const getExtraColumns = (type) => {
        if (type === 'camps_report') {
          return extraColumns
        } else {
          return []
        }
      }

      setColumns(
        [serialNo, districtColumn, blockColumn, mvuColumn, newColumn, ...getExtraColumns(type), ...fetchedData, , timeStampColumn]
          // .filter((key) => !hiddenColumns.includes(key))
          .map((key) => (
            setCoulmn(key)
          ))
      );
      fetchData(selectedDate);

    }
  }

  const getContentTypes = (type) => {
    if (type === "login_report") {
      return [51];
    } else if (type === "logout_report") {
      return [52];
    } else if (type === "camps_report") {
      return [32, 33, 34, 35, 36];
    } else if (type === "morning_report") {
      return [2, 49];
    } else if (type === "evening_report") {
      return [4, 50];
    } else if (type === "fuel_report") {
      return [18, 19, 20, 21, 22, 23, 24, 25, 26, 44, 48];
    } else if (type === "repair_report") {
      return [27, 28, 29, 30, 31];
    } else if (type === "delivery_report") {
      return [37, 38, 38, 40, 41, 42, 43];
    } else if (type === "daily_report") {
      return [5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17];
    }
    return [];
  };
  const getApiPath = (model, action) => {
    if (model === "users" && action === "post") {
      return `${model}/register`;
    } else if (model === "users" && action === "put") {
      return `${model}`;
    } else if (model === "users" && action === "get") {
      return `${model}`;
    }
    return `api/${model}`;
  }

  const getDisplayName = (type) => {
    if (type === "login_report") {
      return "Login Report"
    } if (type === "logout_report") {
      return "Logout Report"
    } else if (type === "camps_report") {
      return "Campaigns Report"
    } else if (type === "morning_report") {
      return "Morning Photo Report"
    } else if (type === "evening_report") {
      return "Evening Photo Report"
    } else if (type === "fuel_report") {
      return "Fuel Filling Report"
    } else if (type === "repair_report") {
      return "Repair & Maintenance Report"
    } else if (type === "delivery_report") {
      return "Medicines & Consumables Report"
    } else if (type === "daily_report") {
      return "Daily Uploads  Report"
    }
    return type
  }

  const calculateWidth = (rows, field, charWidth = 10) => {

    if (type === 'camps_report') {

      if (field == 0) {
        return {
          flex: 1,
          width: 150
        };
      } else if (field == 32) {
        return {
          flex: 1,
          width: 150
        };
      } else if (field == 999) {
        return {
          flex: 0,
          width: 150
        };
      } else if (field == 997) {
        return {
          flex: 0,
          width: 65
        };
      } else if (field == 35) {
        return {
          flex: 0,
          width: 60
        };
      } else if (field == 36) {
        return {
          flex: 0,
          width: 70
        };
      } else if (field == 51) {
        return {
          flex: 1,
          width: 150
        };
      } else if (field == 52) {
        return {
          flex: 1,
          width: 150
        };
      }
      // const maxContentLength = Math.max(
      //   ...rows.map((row) => String(row[field] || '').length)
      // );
      // return maxContentLength * charWidth;
      return { flex: 0, width: 50 };
    } else {
      if (field == 1000) {
        return { flex: 0, width: 50 }
      } else {
        return { flex: 1 }
      }
    }
  };

  const getImageName = (text) => {
    const match = text.match(/\/\/(.*?)\//);

    if (match) {
      return match[1];

    } else {
      return "";
    }
  }

  const setCoulmn = (key) => {
    var column = {
      field: key.id,
      headerName: columnNameOverrides[key.name] || key.name,
      editable: false,
      sort: 'DSC',
      ...calculateWidth(data, key.id),

    }

    if (key.format === "image") {
      column = {
        ...column, renderCell: (params) => (
          <ImageCell imageUrl={getAWSImageUrl(params.row[key.id])} />
        ),
      }
    } else if (key.format === "video") {
      column = {
        ...column, renderCell: (params) => (
          <VideoCell videoUrl={getAWSImageUrl(params.row[key.id])} />
        ),
      }
    }

    if (key.id >= 992 && key.id <= 998) {
      return { ...column, valueGetter: (params) => parseInt(params.row[33]?.[key.name] ?? 0, 10) }
    }
    else if (key.id === 33) {
      return { ...column, valueGetter: (params) => params.row[key.id] ? Object.values(params.row[key.id]).reduce((sum, value) => sum + parseInt(value, 10), 0) : 0 }
    } else if (key.id === 34 || key.id === 36) {
      return { ...column, valueGetter: (params) => params.row[key.id] || 0 }
    } else if (key.id === 35) {
      return { ...column, valueGetter: (params) => params.row[key.id] || 0 }
    }
    else {
      return column
    }
  }
  const getAWSImageUrl = (relativeUrl) => {
    try {
      if (relativeUrl.startsWith("uploads")) {
        // Extract the portion after "file-"
        const regex = /file-([\d-]+)\./; // Matches the part after "file-" and before "."
        const match = relativeUrl.match(regex);
        const imageIdentifier = match ? match[1] : "";
        return `https://biharteammvuphotos.s3.ap-south-1.amazonaws.com/${imageIdentifier}_uploadFile.jpg`;
      } else {
        return `https://biharteammvuphotos.s3.ap-south-1.amazonaws.com/${relativeUrl}`;
      }
    } catch {
      return ''
    }
  };
  const hiddenColumns = [
    'updatedAt',
    'lastName',
    'Blocks',
    'Coordinator',
    'District',
    'Block',
    'ParavetDetails',
    'DocDetails',
    'DriverDetails',
    'Villages',
    'userId',
    'contentTypeId'];

  if (type == "login_report") {
    hiddenColumns.concat('createdAt')
  }
  if (type == "logout_report") {
    hiddenColumns.concat('content')
  }
  const columnNameOverrides = {
    'Animals Treated': 'Total',
    'Number of AI': 'AI',
    'Number of Castrations': 'Castrations',
    'Medicine provided': 'Medicine',
    'Check In': 'App Version'
  };
  const reorderedColumns = (columns) => columns.sort((a, b) => {
    var order = ["User", "Activity", "Time", "Data", "Version"]; // Desired order
    if (type === 'camps_report') {
      order = ["Paravet Name",
        "Villages Visited",
        "Cows", "Buffaloes",
        "Goats",
        "Sheep",
        "Pigs",
        "Poultry",
        "Others",
        "Total",
        "AI",
        "Medicine",
        "Castrations",
        "Time"
      ];
    }
    return order.indexOf(a.headerName) - order.indexOf(b.headerName);
  });
  const getRowHeight = () => {
    if (type == "morning_report" || type == "evening_report") {
      return 80;
    } else {
      return 50;
    }
  }
  const convertToIST = (utcTime) => {
    const utcDate = new Date(utcTime); // Parse UTC time
    const offset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30 in milliseconds
    const istDate = new Date(utcDate.getTime()) //+ offset); // Add offset to UTC time
    return istDate.toLocaleString("en-IN", { timeZone: "Asia/Kolkata", hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: '2-digit' }); // Format as per IST
  };
  const mvuMapping = JSON.parse(localStorage.getItem("mvuMapping"));
  const districts = JSON.parse(localStorage.getItem("districts"));

  function getUserMappings(userId, key, subkey) {
    const record = mvuMapping.find(item => item.userId === userId);
    if (subkey == undefined)
      return record ? record[key] : "N/A";
    else
      return record ? record[key][subkey] : "N/A";
  }

  function getDistrictName(id) {
    const record = districts.find(item => item.id === id);
    return record ? record.name : "N/A";
  }
  const fetchData = async (date) => {

    try {

      var filters = [["contentTypeId", "IN", JSON.stringify(getContentTypes(type))]];
      filters.push(["userId", "IN", localStorage.getItem("userIds")]);
      filters.push(["createdAt", "EQ", date]);
      const query_params = { filters: JSON.stringify(filters) }
      const response = await apiClient.get(`${config.API_BASE_URL}/${getApiPath(model, "get")}`, { params: query_params });
      const fetchedData = response.data;

      // Group by userId and contentTypeId and take the record with the highest id
      const uniqueData = Object.values(
        fetchedData.reduce((acc, record) => {
          const key = `${record.userId}-${record.contentTypeId}`;
          if (!acc[key] || acc[key].id < record.id) {
            acc[key] = record;
          }
          return acc;
        }, {})
      );




      // Sort the unique data by id in descending order
      const sortedData = uniqueData.sort((a, b) => b.id - a.id);
      let serialNumber = 1; // Initialize serial number

      const rearranged = sortedData.reduce((acc, row) => {
        const userId = row.userId;

        // Create a new user entry if it doesn't exist
        if (!acc[userId]) {
          acc[userId] = {
            id: `row-${userId}`, // Unique row ID
            userId,
            0: row.User.firstName,
            "-1": getUserMappings(userId, "mvuNumber"),
            "-2": getUserMappings(userId, "Block", "name"),
            "-3": getDistrictName(getUserMappings(userId, "Block", "districtId")),
            createdAt: row.createdAt,
            999: convertToIST(row.updatedAt).replaceAll("/", "-").replaceAll(",", ""),
          };
        }

        // Add contentTypeId as a column with its content
        acc[userId][row.contentTypeId] =
          row.content.startsWith("{") ? JSON.parse(row.content) : row.content;

        // Update timestamps
        acc[userId].createdAt = acc[userId].createdAt < row.createdAt ? acc[userId].createdAt : row.createdAt;
        acc[userId].updatedAt = acc[userId].updatedAt > row.updatedAt ? acc[userId].updatedAt : row.updatedAt;

        return acc;
      }, {});

      // Convert the result to an array
      const result = Object.values(rearranged).map((item, index) => ({
        ...item,
        1000: index + 1, // Add serial number starting from 1
      }));
      console.log(result);
      setData(result);
      setTime(`As of ${new Date().toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')} 
            ${new Date().toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit', hour12: true })}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    try {
      if (isEdit) {
        if (model === 'users') {
          formData.role = formData.role.toString();
        }
        await apiClient.put(`${config.API_BASE_URL}/${getApiPath(model, "put")}/${editId}`, formData);
      } else {
        const role = formData.role;
        // Define the default password based on the role
        let defaultPassword = '';
        defaultPassword = [1, 2, 3].includes(role) ? 'Test@1234' : 'mvu@web';
        formData.password = defaultPassword; // Set the password in the formData
        if (model === 'users') {
          formData.lastName = ' '
        }

        await apiClient.post(`${config.API_BASE_URL}/${getApiPath(model, "post")}`, formData);
      }
      fetchData(selectedDate);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiClient.delete(`${config.API_BASE_URL}/api/${model}/${id}`);
      fetchData(selectedDate);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({});
    setIsEdit(false);
    setEditId(null);
  };

  const openForm = (row = null) => {
    if (row) {
      setIsEdit(true);
      setEditId(row.id);
      setFormData(row);
    }
    setOpen(true);
  };

  const downloadExcel = () => {
    // Prepare data for Excel
    const worksheetData = data.map(row =>
      reorderedColumns(columns).reduce((acc, col) => {
        if (col.field >= 992 && col.field <= 998) {
          acc[col.headerName] = parseInt(row[33]?.[col.headerName] ?? 0, 10)
        }else if(col.field==33){
          acc[col.headerName] = row[col.field]? Object.values(row[col.field]).reduce((sum, value) => sum + parseInt(value, 10), 0) : 0 
        }else{
          acc[col.headerName] = row[col.field];
        }
        return acc;
      }, {})
    );

    // Create a worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DataGrid");

    // Download the Excel file
    XLSX.writeFile(workbook,
      getDisplayName(type) + time + ".xlsx");
  };


  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={getDisplayName(type)} subtitle={time} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" gap={2}>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
                fetchData(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                />
              )}
            />
            <Button
              onClick={() => {
                fetchData(selectedDate);
              }}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <Sync sx={{ mr: "10px" }} />
              Refresh
            </Button>
            <Button
              onClick={() => {
                downloadExcel();
              }}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              <Download sx={{ mr: "10px" }} />
              Download
            </Button>
          </Box>
        </LocalizationProvider>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      ><Box
        gridColumn="span 12"
        gridRow="span 3"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >

          <DataGrid
            rows={data}
            columns={reorderedColumns(columns)}
            rowHeight={getRowHeight()}
            getRowId={(row) => row.id}
          />
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit Record' : 'Add Record'}</DialogTitle>
        <DialogContent>
          {columns
            .filter((col) => col.field !== 'id' && col.field !== 'actions')
            .map((col) => (
              <TextField
                key={col.field}
                label={col.headerName}
                value={formData[col.field] || ''}
                onChange={(e) =>
                  setFormData({ ...formData, [col.field]: e.target.value })
                }
                fullWidth
                margin="dense"
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Report;
