import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import SettingsOutlinedIcon  from "@mui/icons-material/SettingsOutlined";
import { AirportShuttleOutlined, CampaignOutlined, LogoutOutlined } from "@mui/icons-material";
import Profile from "../../components/Profile";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography variant="h5" fontWeight="600">{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.sideBarBackground} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "0px 15px 5px 20px !important",
        },
        "& .pro-menu-item:not(.active) .pro-inner-item": {
          color: `${colors.sideBarTextColor} !important`,
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu 
          iconShape="square"
        >
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon sx={{ color: colors.sideBarTextColor }} /> : undefined}
            style={{
              margin: "0px 0 0px 0",
              color: colors.sideBarTextColor,
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h3" color={colors.sideBarTextColor} fontWeight="600">
                  WorkBook
                </Typography>
                <IconButton 
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  sx={{ color: colors.sideBarTextColor }}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
              <Profile/>
          )}

          <Box paddingLeft={isCollapsed ? undefined :undefined}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
 {/*            <Item
                title="MVUs List"
                to="/mvu"
                icon={<AirportShuttleOutlined />}
                selected={selected}
                setSelected={setSelected} />
                
                <Item
                title="Excel Data"
                to="/excel"
                icon={<AirportShuttleOutlined />}
                selected={selected}
                setSelected={setSelected} /> 
             <Item
              title="Login Report"
              to="/report/content-uploads?type=login_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Logout Report"
              to="/report/content-uploads?type=logout_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Morning Photo Report"
              to="/report/content-uploads?type=morning_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Evening Photo Report"
              to="/report/content-uploads?type=evening_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Daily Uploads  Report"
              to="/report/content-uploads?type=daily_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Fuel Filling Report"
              to="/report/content-uploads?type=fuel_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Repair & Maintenance Report"
              to="/report/content-uploads?type=repair_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Campaigns Report"
              to="/report/content-uploads?type=camps_report"
              icon={<CampaignOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Medicines & Consumables Report"
              to="/report/content-uploads?type=delivery_report"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
        
           */}

            { (localStorage.getItem("role")==5 || localStorage.getItem("role")==6) && 
             <>
             {/* <Item
                title="MVUs"
                to="/crud/mvu"
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected} /> 
               
                  <Item
                  title="Doctors"
                  to="/crud/users/3"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
                  <Item
                  title="Paravets"
                  to="/crud/users/2"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
                  <Item
                  title="Drivers"
                  to="/crud/users/1"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
        */}
                  </>
}

{ localStorage.getItem("role")==6 &&
                <>
                {/*<Item
                  title="DC Mapping"
                  to="/crud/coordinators"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
                 
                  <Item
                  title="Blocks"
                  to="/crud/blocks"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
                  <Item
                  title="Districts"
                  to="/crud/districts"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
                  */}
                   <Item
                  title="Users List"
                  to="/crud/users"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
                  <Item
                  title="Content Mangement"
                  to="/crud/business-types"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
              </>
}
             <Item
              title="Logout"
              to="/logout"
              icon={<LogoutOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
           
            {/* <Item
              title="Manage Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Manage Events"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
           
            <Item
              title="Manage Spares"
              to="/spares"
              icon={<SettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
