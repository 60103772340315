import React, { useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

const VideoCell = ({ videoUrl, title }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDownload = () => {
    // Trigger the download of the video
    const link = document.createElement("a");
    link.href = videoUrl;
    link.download = "video.mp4"; // Customize the filename as needed
    link.click();
  };

  return (
    <div>
      {/* Video Icon in the Cell */}
      <PlayCircleOutlineIcon
        style={{
          fontSize: "50px",
          color: "#1976d2",
          cursor: "pointer",
        }}
        onClick={handleOpen} // Open the modal on click
      />

      {/* Popup Modal for Video */}
      <Modal open={open} onClose={handleClose} disableAutoFocus>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600, // Adjust width of the popup
            bgcolor: "white", // White background color
            boxShadow: 24, // Shadow effect
            borderRadius: "10px", // Rounded corners
            overflow: "hidden", // Ensure no overflow
            outline: "none",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 15px",
              borderBottom: "1px solid #ccc",
              backgroundColor: "#f5f5f5",
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <Box>
              {/* Download Button */}
              <Button
                variant="contained"
                size="small"
                sx={{ marginRight: "10px" }}
                onClick={handleDownload}
              >
                Download
              </Button>
              {/* Close Button */}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Video Content */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              backgroundColor: "#fff",
            }}
          >
            <video
              src={videoUrl}
              controls
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "500px", // Adjust the max height of the video
                objectFit: "contain",
              }}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default VideoCell;
