import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useState, useEffect } from "react";
import { Call, CallMadeOutlined, PhoneIphone, Sync } from "@mui/icons-material";
import { useSelector } from 'react-redux';
import PolygonMap from "../../components/PolygonMap";
import VillageMap from "../../components/VillageMap";
import Chip from '@mui/material/Chip';
import apiClient from '../../networking/axiosconfig';
import StatBoxMultipleLines from "../../components/StatBoxMultipleLines";

const Dashboard = ({ callBack }) => {
  const { data: config, loading, error } = useSelector((state) => state.config);
  const [mvuStats, setMVUStats] = useState({});
  const [time, setTime] = useState("");
  const fetchMVUData = async () => {  
    try {
      const params = {districtIds:localStorage.getItem("districtIds")}
      const response = await apiClient.get(config.API_BASE_URL+"/api/dashboard",{params});
      localStorage.setItem("userIds",JSON.stringify(response.data.userIds));
      setMVUStats(response.data);
      setTime(`As of ${new Date().toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')} 
      ${new Date().toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit', hour12: true })}`);
    } catch (error) {
      console.error("Error fetching MVU data:", error);
      setMVUStats([])
    }
  };
  
    useEffect(() => {
      if(callBack!=undefined){
        callBack();
      }
      fetchMVUData();
    }, [callBack]);
  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle={time} />
        <Box>
          <Button
          onClick={()=>{
            fetchMVUData();
          }}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Sync sx={{ mr: "10px" }} />
            Refresh Data
          </Button>
        </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

         {mvuStats &&  mvuStats.cards && mvuStats.cards.map((mvuStat) => (
        <Box
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
            <StatBox
            title={mvuStat.count  || 0}
            total={mvuStat.total || 0}
            subtitle={mvuStat.title}
            progress={mvuStat.progress}
            increase={mvuStat.increase}
            icon={
              <EmailIcon
                sx={{ color: colors.cardsAccent, fontSize: "26px" }}
              />
            }
          />
        </Box>
        ))} 

        {mvuStats &&  mvuStats.multilineCards && 
        <Box
          gridColumn="span 3"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxMultipleLines
              data={mvuStats.multilineCards}
            />
            
        </Box>
        } 

        { mvuStats.not_logged_in_users &&
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${colors.cardsAccent}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              MVUs Not Logged In List
            </Typography>
            {/* {mvuStats.length > 0 && mvuStats.length>0 && ( */}
            <Typography color={colors.cardsProgressTrack} variant="h5" fontWeight="600">
              <span style={{fontSize:"32px"}}>{mvuStats.not_logged_in_users.length}</span> MVUs
            </Typography>
            {/* )} */}

          </Box>
          { 
          mvuStats.not_logged_in_users && 
          mvuStats.not_logged_in_users.map((transaction, i) => ( 
           
            <Box
              key={`${transaction.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`1px solid ${colors.cardsAccent}`}
              p="15px"
            >
              <Box>
               
                <Typography
                  color={colors.cardsProgressTrack}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.ParavetDetails.firstName}
                </Typography>
                
                <Typography color={colors.grey[100]}>
                  {transaction.mvuNumber}
                </Typography>
              </Box>  
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                display="flex"
                alignItems="center"
              >
                
                <Call style={{ marginRight: '8px' }} />
                <Typography variant="h5">{transaction.ParavetDetails.username}</Typography>
              </Box>
            </Box>
            
          ))} 
        </Box>
        }
        { mvuStats.animalsCountByCategory &&
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
                    <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${colors.cardsAccent}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Animals Treated by Category
            </Typography>
            <Typography color={colors.cardsProgressTrack} variant="h5" fontWeight="600">
              <span style={{fontSize:"32px",color:'transparent'}}>   </span>
            </Typography>
            

          </Box>
          <Box height="100%" mt="-20px">
            <BarChart isDashboard={true} data={mvuStats.animalsCountByCategory}/>
          </Box>
        </Box>
        }
        { mvuStats.villagesVisited &&
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`1px solid ${colors.cardsAccent}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Villages Visited
            </Typography>
            {mvuStats.villagesVisited > 0  && (
            <Typography color={colors.greenAccent[500]} variant="h5" fontWeight="600">
              <span style={{fontSize:"32px"}}>{mvuStats.villagesVisited }</span> Villages
            </Typography>
            )}

          </Box>
          {mvuStats.villagesVisited  && mvuStats.villagesVisited.map((transaction, i) => (
            transaction.length > 0 && (<>
              <Chip label={transaction} style={{margin:'10px',fontSize:'14px',fontWeight:'bold',backgroundColor:`${colors.greenAccent[500]}`}}/>
                </>
              
            )
          ))} 
        </Box>
        }

{mvuStats.villagesVisited  &&
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          padding="0px"
          position="relative" 
        > 
          <VillageMap></VillageMap>
          <Typography
          top="0%" // Center vertically
          left="0%" 
           zIndex="1000"
           position="absolute"
           variant="h5"
           fontWeight="600"
           sx={{ padding: "30px 30px 0 30px" }}
          >
           Heat Map
          </Typography>
        </Box>
}
      </Box>
    </Box>
  );
};

export default Dashboard;
