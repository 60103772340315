import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Button, Box, Typography, Paper } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiClient from './networking/axiosconfig';

const LoginPage = ({ callBack }) => {

  const showErrorMessage = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  const { data: config, loading, error } = useSelector((state) => state.config);

  const [credentials, setCredentials] = useState({ username: "", password: "", appVersion:"1.3" });
  const [errors, setErrors] = useState({ username: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));

    // Clear errors as user types
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validate = () => {
    const newErrors = {};
    if (!credentials.username) {
      newErrors.username = "Username is required";
    }
    if (!credentials.password) {
      newErrors.password = "Password is required";
    } else if (credentials.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const postLoginData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(credentials);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.API_BASE_URL + "/users/authenticate", requestOptions)
      .then((response) => {
        if (!response.ok) {
          showErrorMessage(`Login failed with status ${response.status}`);
          throw new Error(`Login failed with status ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result.id) {
          if(result.role<3){
            console.log("Login successful but invalid access", result);
            showErrorMessage("These Login details belongs to Doctor,Paravet or Driver who are not allowed to access admin panel.");
          }else{
            console.log("Login successful!", result);
            localStorage.setItem('token',result.token);
            localStorage.setItem('districtIds',[]);
            localStorage.setItem('role',result.role);
            localStorage.setItem('firstName',result.firstName);
            localStorage.setItem('username',result.username);
            if(result.role>=4){
              localStorage.setItem('districtIds',[]);
            }else{
              localStorage.removeItem("districtIds")
            }
            if(result.role==4){
              localStorage.setItem("roleName","Manager")
            }else if(result.role==5){
              localStorage.setItem("roleName","Admin")
            }else if(result.role==6){
              localStorage.setItem("roleName","Super Admin")
            }
            //fetchUserMappings();
            callBack();
          }
          
        } else {
          showErrorMessage("Login failed: ", result.message || "Unknown error");
          console.error("Login failed: ", result.message || "Unknown error");
        }
      })
      .catch((error) => {
        //showErrorMessage("An error occurred during login: ", error.message);
        console.error("An error occurred during login: ", error.message);
      });
  };

  const fetchUserMappings = async () => {

    try {
        const query_params = {}
        //query_params.filters = JSON.stringify([["userId","IN",localStorage.getItem("userIds")]]);
        const response = await apiClient.get(`${config.API_BASE_URL}/api/mvu`,{ params: query_params } );
        const fetchedData = response.data.sort((a, b) => b.id - a.id);
        localStorage.setItem("mvuMapping",JSON.stringify(fetchedData));
        fetchDistricts();
    } catch (error) {
        console.error(error);
    }
};
const fetchDistricts = async () => {

  try {
      const query_params = {}
      //query_params.filters = JSON.stringify([["userId","IN",localStorage.getItem("userIds")]]);
      const response = await apiClient.get(`${config.API_BASE_URL}/api/districts`,{ params: query_params } );
      const fetchedData = response.data.sort((a, b) => b.id - a.id);
      localStorage.setItem("districts",JSON.stringify(fetchedData));
      callBack();
  } catch (error) {
      console.error(error);
  }
};


  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("Login Submitted: ", credentials);
      postLoginData();
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f4f4f9",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          maxWidth: 400,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography variant="h1" gutterBottom>
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            name="username"
            value={credentials.username}
            onChange={handleChange}
            error={Boolean(errors.username)}
            helperText={errors.username}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            type="password"
            margin="normal"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
          >
            Login
          </Button>
          <ToastContainer/>

        </form>
      </Paper>
    </Box>
  );
};

export default LoginPage;
