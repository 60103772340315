import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme, IconButton, MenuItem,
    Select,
    InputLabel,
    FormControl,
    Autocomplete,
} from '@mui/material';
import { Add,Download, Sync } from "@mui/icons-material";
import apiClient from '../../networking/axiosconfig';
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { capitalizeFirstLetter } from '../../Utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";

const MVU = () => {
    const { data: config, loading, error } = useSelector((state) => state.config);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const model = "mvu"
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState(null);
    const [time, setTime] = useState("");
    const [dataCorection, setDataCorrection] = useState(false);
    const [suggestedData,setsuggestedData] = useState([])
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");

    const [filters, setFilters] = useState({}); // State to store search filters
    const [filteredRows, setFilteredRows] = useState({}); // State for filtered rows

    useEffect(() => {
        fetchBlocks()
    }, []);

    const getApiPath = (model, action) => {
        if (model === "users" && action === "post") {
            return `${model}/register`;
        } else if (model === "users" && action === "put") {
            return `${model}`;
        } else if (model === "users" && action === "get") {
            return `${model}`;
        }
        return `api/${model}`;
    }
    const reorderedColumns = (columns) => columns.sort((a, b) => {
        var order = ["Id", "District", "Block", "MVU Number", "CUG", "Paravet Details", "Doctor Details", "Driver Details"]; // Desired order
        return order.indexOf(a.headerName) - order.indexOf(b.headerName);
    });
    const fetchData = async () => {

        try {
            const query_params = {}
            query_params.filters = JSON.stringify([["userId", "IN", localStorage.getItem("userIds")]]);
            const response = await apiClient.get(`${config.API_BASE_URL}/${getApiPath(model, "get")}`, { params: query_params });
            const fetchedData = response.data.sort((a, b) => b.id - a.id);
            const wrong_mvus = [];
    //         if(suggestedData){
    //         var count = 0;
    //         fetchedData.map(async formData=>{
    //         if(!formData.MVU){
    //             const sug_data = suggestedData.filter(item=>item["MVU Number"]==formData.mvuNumber)
    //             if(sug_data[0]){
    //             //const sug_id = docOptions.filter(item=>item.username==(sug_data[0]["Paravet"]))
    //             const sug_id = formData.ParavetDetails.username!=(sug_data[0]["CUG"])
    //             if(sug_id){
    //             //formData.docId=sug_id[0]?sug_id[0].id:null
    //             //formData.DocDetails=sug_id[0]?sug_id[0]:null
    //             count++;
    //             //console.log("existing",count,formData.mvuNumber,formData.ParavetDetails.firstName,sug_data[0]["Paravet"])
    //             const sug_id_1 = userOptions.filter(item=>item.username==(sug_data[0]["CUG"]))
    //             if(sug_id_1[0]){
    //             console.log("update",count,formData.mvuNumber,sug_id_1[0]["firstName"],sug_data[0]["Paravet Details"])
    //             }
    //             {
    //                 if(sug_data[0]["CUG"]!='Mobile & Sim Not Available'){
    //                 wrong_mvus.push(sug_data[0]["MVU Number"])
    //                 formData.Correctcug = sug_data[0]["CUG"]
    //                 }
    //             }
    //                 //formData.Correctmvu = sug_data[0]["MVU Number"]+"-"+"temp"
    //                 //formData.mvuNumber = sug_data[0]["MVU Number"]+"-"+"temp"
    //                 //formData.Correctcparavet = sug_data[0]["Paravet Details"]?sug_data[0]["Paravet Details"]:null
                    
    //             }
    //             }
    //         }
    //         // if(!formData.driverId){
    //         //     const sug_data = suggestedData.filter(item=>item.MVU==formData.mvuNumber)
    //         //     if(sug_data[0]){
    //         //     const sug_id = driverOptions.filter(item=>item.username==(sug_data[0]["Driver Mobile"]))
    //         //     if(sug_id[0]){
    //         //     formData.driverId=sug_id[0]?sug_id[0].id:null
    //         //     formData.DriverDetails=sug_id[0]?sug_id[0]:null
    //         //     console.log("update",formData.driverId)
    //         //     //await apiClient.put(`${config.API_BASE_URL}/${getApiPath(model, "put")}/${formData.id}`, formData);
    //         //     }
    //         //     }
    //         // }
    //     });
    // }
            setData(fetchedData);
            setTime(`As of ${new Date().toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')} 
            ${new Date().toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit', hour12: true })}`);
            const hiddenColumns = ['createdAt', 'updatedAt', 'blockId', 'userId', 'docId', 'driverId'];

            const columnNameOverrides = {
                mvuNumber: 'MVU Number',   // Map original column names to new header names
                ParavetDetails: 'Paravet Details',
                DocDetails: 'Doctor Details',
                DriverDetails: 'Driver Details',
                isActive: 'Active',
                CUG: 'CUG'
            };

            const setCoulmn = (key) => {
                const column = {
                    field: key,
                    headerName: columnNameOverrides[key] || capitalizeFirstLetter(key),
                    flex: 1,
                    editable: false,
                    sort: 'DSC'
                }
                if (key === "Block") {
                    return { ...column, valueGetter: (params) => params.row[key].name }
                }
                else if (key === "CUG") {
                    return { ...column, valueGetter: (params) => params.row.ParavetDetails ? params.row.ParavetDetails.username : "N/A" }
                }
                else if (key === "ParavetDetails") {
                    return { ...column, valueGetter: (params) => params.row[key] ? params.row[key].firstName : "N/A" }
                } else if (key === "DocDetails") {
                    return { ...column, valueGetter: (params) => params.row[key] ? params.row[key].firstName : "N/A" }
                } else if (key === "DriverDetails") {
                    return { ...column, valueGetter: (params) => params.row[key] ? params.row[key].firstName : "N/A" }
                } else if (key === "District") {
                    return { ...column, valueGetter: (params) => params.row.Block.districtId ? getDistrictName(params.row.Block.districtId) : "N/A" }
                } else {
                    return column
                }
            }
            if (fetchedData.length > 0) {
                const sample = fetchedData[0];
                const columns = Object.keys(sample)
                    .filter((key) => !hiddenColumns.includes(key))
                    .map((key) => (setCoulmn(key)));
                //columns.push(setCoulmn("CUG"))
                setColumns([...columns, setCoulmn("CUG"), setCoulmn("District")]);
                const initialFilters = {};
                columns.forEach((col) => (initialFilters[col.field] = ''));
                setFilters(initialFilters);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const districts = JSON.parse(localStorage.getItem("districts"));
    function getDistrictName(id) {
        const record = districts.find(item => item.id === id);
        return record ? record.name : "N/A";
    }

    const handleSave = async () => {
        try {
            if (isEdit) {
                if (model === 'users') {
                    formData.role = formData.role.toString();
                }
                await apiClient.put(`${config.API_BASE_URL}/${getApiPath(model, "put")}/${editId}`, formData);
            } else {
                const role = formData.role;
                // Define the default password based on the role
                let defaultPassword = '';
                defaultPassword = [1, 2, 3].includes(role) ? 'Test@1234' : 'mvu@web';
                formData.password = defaultPassword; // Set the password in the formData
                if (model === 'users') {
                    formData.lastName = ' '
                }

                await apiClient.post(`${config.API_BASE_URL}/${getApiPath(model, "post")}`, formData);
            }
            fetchData();
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    const getAssignedMVU = (userId) => {
        const matches = data.filter(({ userId: u, docId, driverId }) =>
          [u, docId, driverId].includes(userId)
        );
      
        if (matches.length === 0) {
          return "MVU Not Assigned"; // No match
        } else if (matches.length === 1) {
          return matches[0].mvuNumber; // One match
        } else {
          return matches.map(({ mvuNumber }) => mvuNumber).join(','); // More than 1 match
        }
      };

    // const handleDataCorrection = async (wrongData) => {
    //     try {

    //             wrongData.map(async (item)=>{

    //             const userData = await apiClient.get(`${config.API_BASE_URL}/${getApiPath("users","get")}/${item.docId}`)
    //             {
    //                 userData.data.role = "3"
    //                 await apiClient.put(`${config.API_BASE_URL}/${getApiPath("users","put")}/${item.docId}`, userData.data);
    //             }
    //         }
    //         )
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const handleDelete = async (id) => {
        try {
            await apiClient.delete(`${config.API_BASE_URL}/api/${model}/${id}`);
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({});
        setIsEdit(false);
        setEditId(null);
        setDataCorrection(false)
    };
    const handleFilterChange = (field, value) => {
        setFilters((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const getColumnValue = (row, key) => {

        if (key === "Block") {
            return row[key].name
        }
        else if (key === "CUG") {
            return row.ParavetDetails ? row.ParavetDetails.username : "N/A"
        }
        else if (key === "ParavetDetails") {
            return row[key] ? row[key].firstName : "N/A"
        } else if (key === "DocDetails") {
            return row[key] ? row[key].firstName : "N/A"
        } else if (key === "DriverDetails") {
            return row[key] ? row[key].firstName : "N/A"
        } else if (key === "District") {
            return row.Block.districtId ? getDistrictName(row.Block.districtId) : "N/A"
        } else {
            return row[key];
        }
    }



    useEffect(() => {
        const newFilteredRows = data.filter((row) =>
            Object.keys(filters).every((field) =>
                getColumnValue(row, field)?.toString().toLowerCase().includes(filters[field].toLowerCase())
            )
        );
        setFilteredRows(newFilteredRows);
    }, [filters]);
    const openForm = (row = null) => {
        if (row) {
            setIsEdit(true);
            setEditId(row.id);
            setFormData(row);
        }
        setOpen(true);
    };

    const addActionsColumn = () => {
        if (localStorage.getItem("role") == 5 || localStorage.getItem("role") == 6)
            return {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                renderCell: (params) => (
                    <>
                        <IconButton
                            color="secondary"
                            onClick={() => openForm(params.row)}
                            style={{ marginRight: 10 }}
                        >
                            <EditIcon />
                        </IconButton>
                        { localStorage.getItem("role") == 6 &&
                        <IconButton
                            color="error"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
            }
                    </>
                ),
            }
        else {
            return {

            }
        }
    }
    const [blockOptions, setBlockOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [docOptions, setDocOptions] = useState([]);
    const [driverOptions, setDriverOptions] = useState([]);

    const columnsForEdit = [
        { headerName: "MVU Number", field: "mvuNumber", type: "text" },
        { headerName: "Block", field: "blockId", type: "select", options: blockOptions },
        { headerName: "Paravet Details", field: "userId", type: "select", options: userOptions },
        { headerName: "Doctor Details", field: "docId", type: "select", options: docOptions },
        { headerName: "Driver Details", field: "driverId", type: "select", options: driverOptions },
    ];

    // useEffect(() => {
    //     if (open) {
    //         // Fetch master data when dialog opens
    //         fetchBlocks();
    //     }
    // }, [open]);
    const fetchBlocks = async () => {
        const blocks = await apiClient.get(`${config.API_BASE_URL}/api/masters/blocks`)
        setBlockOptions(blocks.data);
        fetchUsers();
    }
    const fetchUsers = async () => {
        const users = await apiClient.get(`${config.API_BASE_URL}/api/users`)
        {
            setUserOptions(users.data.filter((item) => item.role === 2));
            setDocOptions(users.data.filter((item) => item.role === 3));
            setDriverOptions(users.data.filter((item) => item.role === 1));
            fetchData();
        }
    }
    const downloadExcel = () => {

        const getMappedData =(row,key) =>{
            if (key === "Block") {
                return  row[key].name 
            }
            else if (key === "CUG") {
                return row.ParavetDetails ? row.ParavetDetails.username : "N/A" 
            }
            else if (key === "ParavetDetails") {
                return row[key] ? row[key].firstName : "N/A" 
            } else if (key === "DocDetails") {
                return row[key] ? row[key].firstName : "N/A" 
            } else if (key === "DriverDetails") {
                return row[key] ? row[key].firstName : "N/A" 
            } else if (key === "District") {
                return row.Block.districtId ? getDistrictName(row.Block.districtId) : "N/A" 
            } else {
                return row[key]
            }
        }

        // Prepare data for Excel
        const worksheetData = data.map(row =>
          reorderedColumns(columns).reduce((acc, col) => {
                acc[col.headerName] = getMappedData(row,col.field);
                return acc;
            }, {})
        );
  
        // Create a worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "DataGrid");  
  
        // Download the Excel file
        XLSX.writeFile(workbook,
          "MVU List "+time+".xlsx");
    };
  
    
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Get the first sheet
          const sheet = workbook.Sheets[sheetName];
    
          // Convert sheet data to JSON
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
          // Create columns based on the first row (header row)
          const headers = jsonData[0];
          const dataColumns = headers.map((header, index) => ({
            field: header || `column_${index}`,
            headerName: header || `Column ${index + 1}`,
            flex: 1,
          }));
    
          // Create rows based on the remaining rows
          const dataRows = jsonData.slice(1).map((row, index) => {
            const rowData = {};
            headers.forEach((header, colIndex) => { 
              rowData[header || `column_${colIndex}`] = row[colIndex] || "";
            });
                return { id: index, ...rowData };
          });
    
          //setColumns(dataColumns);
          //setRows(dataRows.filter(item=>item["Driver Role"]!==3 && item["Doctor Mobile"]));
          setsuggestedData(dataRows);
          fetchBlocks()

        };
    
        reader.readAsBinaryString(file);
      };
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={`${capitalizeFirstLetter(model)} List`} subtitle={time} />
                <Box display="flex" gap={2}>
                    {(localStorage.getItem("role") == 5 || localStorage.getItem("role") == 6) &&
                        <Button
                            onClick={() => {
                                openForm();
                            }}
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            <Add sx={{ mr: "10px" }} />
                            Add
                        </Button>
                    }
                    <Button
                        onClick={() => {
                            fetchData();
                        }}
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",

                        }}
                    >
                        <Sync sx={{ mr: "10px" }} />
                        Refresh
                    </Button>
                    <Button
      onClick={() => {
       downloadExcel();
      }}
      sx={{
        backgroundColor: colors.blueAccent[700],
        color: colors.grey[100],
        fontSize: "14px",
        fontWeight: "bold",
        padding: "10px 20px",
      }}
    >
      <Download sx={{ mr: "10px" }} />
      Download
    </Button>
                    {/* <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        style={{ marginBottom: "1rem" }}
      /> */}
                    {/* <Button
          onClick={()=>{
            setDataCorrection(true)          }}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
             
            }}
          >
            <Sync sx={{ mr: "10px" }} />
            DATA CORRECTION
          </Button> */}
                </Box>
            </Box>
            <Box display="flex" gap={2} mb={2}>
                {columns.map((col) => (
                    <TextField
                        key={col.field}
                        label={`Search ${col.headerName}`}
                        variant="outlined"
                        size="small"
                        onChange={(e) => handleFilterChange(col.field, e.target.value)}
                        value={filters[col.field] || ''}
                    />
                ))}
            </Box>
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            ><Box
                gridColumn="span 12"
                gridRow="span 3"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >

                    <DataGrid
                        rows={filteredRows}
                        columns={[
                            ...reorderedColumns(columns),
                            addActionsColumn(),
                        ]}
                        getRowId={(row) => row.id}

                    />
                </Box>
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEdit ? 'Edit Record' : 'Add Record'}</DialogTitle>
                <DialogContent>
                <Stack spacing={2} sx={{ width: 300,paddingTop:1 }}>

                    {columnsForEdit
                        .map((col) => (
                            col.type === "select" ?
                                <Autocomplete
                                    getOptionLabel={(option) => option.name || option.firstName} // Dynamically check for name or firstName
                                    getOptionKey={(option) => option.id} 
                                    options={col.options}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                          <div>
                                            <div>{option.name || option.firstName}</div> {/* Display the name */}
                                            <div style={{ fontSize: '0.85rem', color: 'gray' }}>{option.mobile} {option.role && getAssignedMVU(option.id)}</div> {/* Display the mobile */}
                                          </div>
                                        </li>
                                      )}
                                    value={col.options.find(
                                        (option) => option.id === formData[col.field]
                                    ) || null} // Check both `name` and `firstName` for the initial value
                                    onChange={(event, newValue) => {
                                        // Save the selected value (either `name` or `firstName`) to formData
                                        setFormData({
                                            ...formData,
                                            [col.field]: newValue ? newValue.id : '',
                                        });
                                    }}
                                    getOptionSelected={(option, value) =>
                                        option.id === value.id
                                    } 
                                    // filterOptions={(options, { inputValue }) => 
                                    //     options.filter((option) => {
                                    //         const label = option.name || option.firstName || '';
                                    //       return label.toLowerCase().con(inputValue.toLowerCase()); // Match starts with
                                    //     })
                                    //   }
                                    renderInput={(params) => <TextField {...params} label={col.headerName} />}
                                />

                                : <TextField
                                    key={col.field}
                                    label={col.headerName}
                                    value={formData[col.field] || ''}
                                    onChange={(e) =>
                                        setFormData({ ...formData, [col.field]: e.target.value })
                                    }
                                    fullWidth
                                    
                                />

                        ))}
                        </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Dialog open={dataCorection} onClose={handleClose}>
                <DialogTitle>Data Correction </DialogTitle>
                <DialogContent>
                    {
                        data.filter(item=>item.DocDetails?.role!=3).map(item=>  

                            <p>{item.DocDetails?(item.DocDetails.firstName+" "+item.DocDetails.role):"N/A"}</p>

                        )
                    }
                </DialogContent>
<DialogActions>

                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={()=> handleDataCorrection(data.filter(item=>item.DocDetails!=null && item.DocDetails.role!=3))} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog> */}
        </Box>
    );
};

export default MVU;
