import L from 'leaflet';
import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON ,useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useTheme } from "@mui/material";
import { tokens} from '../theme'
import { useSelector } from 'react-redux';

const VillageMap = () => {
  const { data: config, loading, error } = useSelector((state) => state.config);
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [stateJsonData, setstateJsonData] = useState(null);
  const mapRef = useRef();
  
  useEffect(() => {
    // Load GeoJSON data

    fetch(config.API_BASE_URL+'/biharjson') // Replace with the path to your GeoJSON file
      .then((response) => response.json())
      .then((data) => {setstateJsonData(data)
      fetch(config.API_BASE_URL+'/geojson') // Replace with the path to your GeoJSON file
      .then((response) => response.json())
      .then((data) => {
        setGeoJsonData(data)
        
    }
    )
      .catch((error) => console.error('Error loading GeoJSON:', error));}
    )
      .catch((error) => console.error('Error loading GeoJSON:', error));

  
  }, []);

  return (
    <MapContainer center={[27.44133, 83.90762]} 
    zoom={12} 
    style={{ height: '100%', width: '100%' }}
    whenCreated={(map) => (window.map = map)}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      
      {stateJsonData && (
    
    <FitGeoJSON data={stateJsonData} villages_data={geoJsonData} visited_villages={["Bhaunathpur"]}/>
      )}

    </MapContainer>
  );
};

  const FitGeoJSON = ({ data,villages_data,visited_villages}) => {
    const map = useMap();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    useEffect(() => {
      if (data) {
        // Create GeoJSON Layer for your data
        const geoJsonLayer = L.geoJSON(data);
  
        // Get bounds of the polygon
        const bounds = geoJsonLayer.getBounds();
        
        // Fit the map to the bounds
        map.fitBounds(bounds);
  
        // Disable zoom and scroll
        map.scrollWheelZoom.disable();  // Disable scroll zoom
        map.touchZoom.disable();        // Disable touch zoom (for mobile)
        map.doubleClickZoom.disable();  // Disable double-click zoom
        map.dragging.disable();         // Disable dragging
        map.zoomControl.remove();
        // Create a full map extent layer (to mask the area outside the polygon)
        const fullMapBounds = map.getBounds(); // Get the current map bounds
  
        // Create a polygon for the entire map area
        const fullMapPolygon = L.polygon([
          [
            [fullMapBounds.getSouthWest().lat, fullMapBounds.getSouthWest().lng],
            [fullMapBounds.getNorthEast().lat, fullMapBounds.getSouthWest().lng],
            [fullMapBounds.getNorthEast().lat, fullMapBounds.getNorthEast().lng],
            [fullMapBounds.getSouthWest().lat, fullMapBounds.getNorthEast().lng]
          ]
        ], {
          color: `${colors.primary[400]}`,
          weight: 1,
          fillColor: `${colors.primary[400]}`,
          fillOpacity:1,
          opacity: 1,
          stroke:true
        }).addTo(map);
  
        // Mask the area outside the polygon by adding the polygon's bounds as an overlay
        geoJsonLayer.addTo(map);
  
        // Make sure the polygon area is above the mask (bring it to the front)
        geoJsonLayer.bringToFront();



        // const geoJsonVillageLayer = L.geoJSON(villages_data, {
        //     style: (feature) => {
        //       // Check if the village is in the selected villages list
        //       const isSelected = visited_villages.includes(feature.properties.NAME);
        //       console.log("--->"+visited_villages);
        //       console.log("--->"+feature.properties.NAME);
        //       // Style for selected villages (filled polygon)
        //       return {
        //         color: isSelected ? 'green' : 'red', // Outline color
        //         weight: 2,
        //         fillColor: isSelected ? 'green' : 'red', // Fill color for selected villages
        //         fillOpacity: isSelected ? 0.6 : 0 // Fill opacity for selected villages
        //       };
        //     }
        //   });
    
        //   // Add the GeoJSON layer to the map
        //   geoJsonVillageLayer.addTo(map);
        //   geoJsonVillageLayer.bringToFront();

      }
    }, [data, villages_data,visited_villages,map]);
  
    return data ? <GeoJSON data={data} /> : null;
  };
  
  

export default VillageMap;
