import React from "react";
import { Box, Typography,useTheme } from "@mui/material";
import { tokens } from "../theme";

const Profile = () => {
  // Fetch data from localStorage
  const firstName = localStorage.getItem("firstName") || "First Name";
  const userName = localStorage.getItem("username") || "Username";
  const roleName = localStorage.getItem("roleName") || "Role";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{backgroundColor:colors.cardsProgressTrack,marginLeft:"15px",marginRight:"25px",padding:"10px",borderRadius:3}}
    >
      {/* Logo */}
      <img
        alt="profile-user"
        width="50px"
        height="50px"
        src={`../../assets/logo.png`}
        style={{ cursor: "pointer", borderRadius: "50%" }}
      />

      {/* User Info */}
      <Box
        display="flex"
        flexDirection="column"
        marginLeft="16px"
        textAlign="left"
      >
        <Typography variant="h6" style={{ fontWeight: "bold",color:"white" }}>
          {firstName}
        </Typography>
        <Typography variant="body1" style={{ color: "white" }}>
          {userName}
        </Typography>
        <Typography variant="body2" style={{ color: "white" }}>
          {roleName}
        </Typography>
      </Box>
    </Box>
  );
};

export default Profile;
