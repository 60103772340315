import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import { setConfig, setError } from './store/configSlice';

const fetchConfig = async () => {
    try {
        const response = await fetch('/config.json');
        if (!response.ok) {
            throw new Error(`Failed to fetch config: ${response.statusText}`);
        }
        const config = await response.json();
        store.dispatch(setConfig(config));
    } catch (error) {
        store.dispatch(setError(error.message));
        console.error('Error loading config:', error);
    }
};

const initApp = async () => {
    await fetchConfig(); // Fetch the configuration before rendering the app

    ReactDOM.createRoot(document.getElementById('root')).render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
};

initApp();


