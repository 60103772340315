// src/utils/stringUtils.js

// Capitalize the first letter of a string
export function capitalizeFirstLetter(string) {
    if (!string) return ''; // Handle empty or undefined strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  
  // Capitalize the first letter of every word in a sentence
  export function capitalizeWords(string) {
    if (!string) return '';
    return string
      .split(' ')
      .map(word => capitalizeFirstLetter(word))
      .join(' ');
  }
  
  export function isJsonObject(obj) {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
  }

  export function getUserNameFromId(userId){
    try{
    const userMasterData = JSON.parse(localStorage.getItem("userMasterData"));
    const user = userMasterData.filter((item)=>item.id===userId)[0];
    return user.firstName;
    }catch(ex){
    return userId;
    }
}